import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/webapp/src/app/_providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/webapp/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.26_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwri_m55ssf6azdq62lvmokyouhrgi4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.26_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwri_m55ssf6azdq62lvmokyouhrgi4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.14_next@14.2.26_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwri_m55ssf6azdq62lvmokyouhrgi4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.26_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.49.1_react-dom@18_yj5ys635r4w4y4y7jzlknvxl2a/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.26_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.49.1_react-dom@18_yj5ys635r4w4y4y7jzlknvxl2a/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}");
