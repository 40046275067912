import * as React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { Body } from '../typography/body';

import { cn } from '@/lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipPortal = TooltipPrimitive.Portal;

const tooltipVariants = cn([
  // base layout
  'rounded-sm',
  'z-50',
  'overflow-hidden',
  'py-1',
  'px-2',
  'text-inverse-strong',
  'bg-inverse-canvas-1',
  'shadow-2',
  'max-w-[224px]',
  // animation
  'fade-in-0',
  'animate-in',
  'zoom-in-95',
  'data-[state=closed]:fade-out-0',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:zoom-out-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
]);

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={cn(tooltipVariants, className)}
    sideOffset={sideOffset}
    {...props}
  >
    <Body size="xxxs" variant="medium">
      {children}
    </Body>
  </TooltipPrimitive.Content>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipPortal,
  tooltipVariants,
};
