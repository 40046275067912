'use client';

import React, {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
} from 'react';

import tracking from 'iclinic-trackings';

import { clientEnv } from '@/env/client.mjs';

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsContext = createContext<ReturnType<
  (typeof tracking)['init']
> | null>(null);

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const writeKey = clientEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
  const analytics = useMemo(() => tracking.init(writeKey), [writeKey]);
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
