'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';

import { TooltipProvider } from '@axiom-ui/react/tooltip';

import { RUMProvider } from '@/modules/monitoring/elastic-rum/provider';
import { SnowplowProvider } from '@/modules/monitoring/snowplow/provider';
import { AnalyticsProvider } from '@/modules/tracking/provider';

type NextAuthProviderProps = {
  children?: React.ReactNode;
};

const queryClient = new QueryClient();

export function Providers({ children }: NextAuthProviderProps) {
  return (
    <SessionProvider basePath="/v2/api/auth">
      <QueryClientProvider client={queryClient}>
        <RUMProvider>
          <SnowplowProvider>
            <TooltipProvider delayDuration={0}>
              <AnalyticsProvider>{children}</AnalyticsProvider>
            </TooltipProvider>
          </SnowplowProvider>
        </RUMProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
